// Цвета
$color-black: #000000;
$color-onyx: #111111;
$color-dark-jungle: #222222;
$color-mine-shaft: #333333;
$color-tundora: #444444;
$color-davys-grey: #555555;
$color-ironside-gray: #666666;
$color-tapa-gray: #777777;
$color-taupe-gray: #888888;
$color-mountain-mist: #999999;
$color-dark-gray: #aaaaaa;
$color-gray: #bbbbbb;
$color-celeste: #cccccc;
$color-light-gray: #dddddd;
$color-eallery: #eeeeee;
$color-white: #ffffff;
$color-navy-blue: #0079c1;
$color-prussian-blue: #00426a;
$color-error: #e24831;
$color-carrot-orange: #f39318;

$regal-blue: #133A60;
$cobalt: #00589C;
$navy-blue:#0080C8;
$jordy-blue: #7AB9E5;
$mantis: #71C063;
$yellow-orange: #FBB040;
$picton-blue: #54A4C5;
$solitude: #E6ECF8;
$hawkes-blue: #D1DFF2;
$solitude-grey: #E6E7E8;
$storm-dust: #626261;
$nero: #212121;
$alice-blue: #F3F7FD;
$storm-grey: #808285;
$mischka: #A7A9AC;
$red-orange: #F44336;
$mantis: #78BA54;
$my-sin: #F9B234;
$cornflower: #8ABEE8;

.color-navy-blue {
	color: $color-navy-blue;
}

.color-green {
	color: $mantis;
}

.color-yellow {
	color: $my-sin;
}
