@charset "UTF-8";
.color-navy-blue {
  color: #0079c1; }

.color-green {
  color: #78BA54; }

.color-yellow {
  color: #F9B234; }

/*
	В этом файле собираются fallback'и для браузеров, не поддерживающих FlexBox. Путь берется относительно того, что
	этот файл лежит в темповой директории во время обработки, и подтягивает из компонентов, лежащих во временной
	директории, специальные файлы no-flexbox.
	Сам файл дергается из tzar-core.
*/
/*
	Стили, используемые для элементов из папки style
 */
/*
	link.scss
 */
.b-link-icon {
  display: table; }
  .b-link-icon img {
    display: block;
    width: 25px;
    vertical-align: middle; }
  .b-link-icon__icon {
    display: table-cell;
    vertical-align: middle;
    width: 25px;
    padding-right: 5px; }
  .b-link-icon__text {
    display: table-cell;
    vertical-align: middle; }

.b-link-icon--right .b-link-icon__icon {
  padding-left: 5px;
  padding-right: 0; }

.b-link-icon--size-m .b-link-icon__icon {
  width: 30px; }
  .b-link-icon--size-m .b-link-icon__icon img {
    width: 30px; }

.b-link-icon--size-l .b-link-icon__icon {
  width: 40px; }
  .b-link-icon--size-l .b-link-icon__icon img {
    width: 40px; }
